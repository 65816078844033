<template>
  <DialogFormWrapper class="file-add">
    <template #form>
      <v-text-field
        outlined
        label="Wystaw KWU na osobę zewnętrzną"
        v-model="contactName"
        validate-on-blur
        hide-details
        class="hide-margin pa-0 my-4"
        placeholder="Wpisz imię i nazwisko"
        :disabled="isProcessing"
      >
        <template #append>
          <v-btn
            :style="{ 'margin-bottom': '1px' }"
            icon
            small
            :disabled="!contactName"
            :loading="isProcessing"
            @click="generateKwu({ id: entity.id })"
          >
            <v-icon>mdi-file-image-plus-outline</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <template #submit>
      <v-btn
        outlined
        color="primary"
        :disabled="isProcessing"
        :loading="isProcessing"
        @click="generateKwu({ id: entity.id })"
      >
        Generuj KWU
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data() {
    return {
      contactName: null,
    }
  },
  computed: {
    ...mapState({
      entity: state => state.layout.dialog.item,
      controlName: state => state.order.entity?.client?.controlName,
      isProcessing: state => state.order.isProcessing
    })
  },
  mounted() {
    this.contactName = this.controlName
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      generateKwu: function(dispatch, { id }) {
        dispatch('order/generateKwu', { id, contactName: this.contactName })
          .then(() => {
            this.contactName = null
            this.closeDialog()
          })
      }
    })
  }
}
</script>
